var render = function render(_c,_vm){return _c('div',{staticClass:"product-price",class:[
    { 'has-promo': _vm.$options.isPromoPrice(_vm.props.product) },
    { 'is-list text-md-center': _vm.props.isList }
  ]},[(_vm.$options.isPromoPrice(_vm.props.product))?_c('div',{staticClass:"promo-price"},[_c('div',{staticClass:"old-price"},[_c('span',{staticClass:"price"},[_c('i18n-n',{staticClass:"formatted-price",attrs:{"value":_vm.props.product.priceStandardDisplay,"format":"currency","tag":"span"},scopedSlots:_vm._u([{key:"literal",fn:function(){return undefined},proxy:true},{key:"currency",fn:function(slotProps){return [_c('span',{staticClass:"currency small"},[_vm._v(_vm._s(slotProps.currency))])]}}],null,false,2697816124)})],1)]),_c('div',{staticClass:"new-price"},[_c('span',{staticClass:"price"},[_c('i18n-n',{staticClass:"formatted-price",attrs:{"value":_vm.props.product.priceDisplay,"format":"currency","tag":"span"},scopedSlots:_vm._u([{key:"literal",fn:function(){return undefined},proxy:true},{key:"currency",fn:function(slotProps){return [_c('span',{staticClass:"currency"},[_vm._v(_vm._s(slotProps.currency))])]}}],null,false,2007207139)})],1)])]):_c('div',{staticClass:"no-promo-price"},[_c('div',{staticClass:"price_block"},[(_vm.props.product.priceDisplay)?_c('div',{staticClass:"cur-price"},[_c('i18n-n',{staticClass:"formatted-price",attrs:{"value":_vm.props.product.priceDisplay,"format":"currency","tag":"span"},scopedSlots:_vm._u([{key:"literal",fn:function(){return undefined},proxy:true},{key:"currency",fn:function(slotProps){return [_c('span',{staticClass:"currency"},[_vm._v(_vm._s(slotProps.currency))]),(_vm.props.product.productInfos.TIPOLOGIA != 'Pezzo')?_c('span',{staticClass:"weight-unit"},[_vm._v("/"+_vm._s(_vm.props.product.weightUnitDisplay)+" ")]):_vm._e()]}}],null,false,2126365376)})],1):_vm._e()])]),(
      _vm.props.product.productInfos &&
        _vm.props.product.productInfos.TIPOLOGIA == 'Pezzo' &&
        _vm.props.product.priceUmDisplay &&
        !_vm.props.isList
    )?_c('span',{staticClass:"price-um"},[_c('i18n-n',{staticClass:"formatted-price small",attrs:{"value":_vm.props.product.priceUmDisplay,"format":"currency","tag":"span"},scopedSlots:_vm._u([{key:"currency",fn:function(slotProps){return [_c('span',{staticClass:"currency small"},[_vm._v(_vm._s(slotProps.currency))]),_c('span',{staticClass:"text-lowercase"},[_vm._v("/"+_vm._s(_vm.props.product.weightUnitDisplay))])]}}],null,false,2048999321)})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }