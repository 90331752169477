<template functional>
  <!-- qty wrap modificativo -->
  <div
    class="qty-wrap rounded-sm"
    :class="{
      'not-empty': parent.quantity > 0,
      'show-add-btn': parent.quantity < 1
    }"
    @mousedown.stop
  >
    <v-btn
      aria-label="Diminuisci quantità"
      depressed
      color="white"
      small
      class="minus quantity-btn"
      @click.stop.prevent="
        parent.minus(
          true,
          { infos: { ...props.selectedOptions } },
          {
            searchUid: props.product.searchUid,
            position: props.position
          }
        )
      "
    >
      <v-icon>$minus</v-icon>
    </v-btn>
    <div class="val-cnt">
      <span class="val">{{ parent.quantity }}{{ parent.unit }}</span>
      <span class="small">{{ parent.quantityPerUnit }}</span>
    </div>
    <v-btn
      aria-label="Aumenta quantità"
      class="plus quantity-btn"
      color="white"
      small
      depressed
      @click.stop.prevent="
        parent.plus(
          true,
          {
            infos: {
              ...props.selectedOptions
            }
          },
          {
            searchUid: props.product.searchUid,
            position: props.position
          }
        )
      "
    >
      <v-icon>$plus</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "ProductQty",
  props: {
    product: { type: Object, required: true },
    item: { type: Object, required: false },
    label: { type: String },
    selectedOptions: { type: Object },
    position: { type: Number, default: undefined }
  }
};
</script>
